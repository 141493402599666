var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-4"},[_c('h6',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDelete),expression:"isDelete"}]},[_c('v-icon',{staticClass:"pb-1"},[_vm._v("mdi-numeric-2-box-outline")]),_vm._v(" Productos de la salida ")],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataList,"search":_vm.search,"height":"auto","fixed-header":"","footer-props":{
      'items-per-page-text': _vm.$t('table.item_table'),
      'items-per-page-all-text': _vm.$t('table.all'),
      'items-per-page-options': [15, 30, 50, 100],
    },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search'),"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row justify-end"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 pr-6 text-right"},[_c('v-text-field',{staticClass:"br",attrs:{"suffix":_vm.search == '' ? '' : 'enter',"label":"Buscar..."},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchbar.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"pt-2"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.search != ''),expression:"search != ''"}],staticClass:"feather icon-corner-down-left"})])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.statusName",fn:function(ref){
    var item = ref.item;
return [_c('ChipTableComponent',{attrs:{"status":item.status,"statusName":item.statusName}})]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(_vm.isDelete)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"mr-2 hand",attrs:{"disabled":_vm.sending,"small":""},on:{"click":function($event){return _vm.deleteRecord(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-trash-2"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.delete")))])]):_vm._e(),(_vm.isDetail)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hand mr-2",on:{"click":function($event){return _vm.detail(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-eye"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.detail")))])]):_vm._e(),(_vm.isUpdate && (item.status == 6 || item.status == 7))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"#E2E2E2"}},[_vm._v(" mdi mdi-square-edit-outline ")]):_vm._e(),(_vm.isUpdate && item.status != 6 && item.status != 7)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.updateRecord(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi mdi-square-edit-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.edit")))])]):_vm._e(),(_vm.isDownloadPDF)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hand text-fmedical-red",on:{"click":function($event){return _vm.downloadPDF(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"fa fa-file-pdf-o"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.download_PDF")))])]):_vm._e()]}}],null,true)}),(_vm.isButton)?_c('ButtonTableComponent',{on:{"excelTable":_vm.excelTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }