<template>
  <validation-observer>
    <form>
      <validation-provider v-slot="{ errors }" name="procurement">
        <v-autocomplete
          :disabled="isDisabled"
          v-model="idShimentRequest"
          :items="dataList"
          @change="branchChangeRequest"
          label="Aprovisionamiento (opcional)"
          :error-messages="errors"
          clearable
          clear-icon="feather icon-x-circle"
          no-data-text="Sin solicitudes"
        />
      </validation-provider>
    </form>
  </validation-observer>
</template>
<script>
import { PreRequest } from "@/core/request";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapState } from "vuex";
import { UtilFront } from "@/core";

export default {
  props: {
    alert: Function,
  },
  data() {
    return {
      idShimentRequest: null,
      dataList: [],
      isDisabled: false,
      originIdBranchoffice: 0,
    };
  },
  computed: {
    // Extración del state de auth para la sucursal global
    ...mapState("auth", {
      branchOfficeState: "branchOffice",
    }),
  },
  methods: {
    branchChangeRequest() {
      this.$emit("branchChangeRequest", this.idShimentRequest);
    },
    reloadComponentDisabled(isDisabled) {
      this.isDisabled = isDisabled;
    },
    reloadComponentIdShimentRequest(id) {
      this.idShimentRequest = id;
    },
    reloadComponentClean() {
      this.idShimentRequest = null;
    },
    async getAllShimentRequest() {
      await PreRequest.getAllRequestSelect({
        userBranchOffice: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.originIdBranchoffice,
        },
      })
        .then((response) => {
          let { data, message, success } = response.data.data;
          if (success) {
            this.dataList = data;
          } else {
            this.dataList = [];
            //this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error(error);
        })
        .finally(() => {});
    },
  },
  async created() {
    this.originIdBranchoffice = this.branchOfficeState.idBranchoffice;
    await this.getAllShimentRequest();
  },
  watch: {
    async branchOfficeState() {
      this.originIdBranchoffice = this.branchOfficeState.idBranchoffice;
      await this.getAllShimentRequest();
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
};
</script>
