var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.disableEnterKey.apply(null, arguments)},"submit":function($event){$event.preventDefault();return _vm.addStockInOrder.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h6',[_c('v-icon',{staticClass:"pb-1"},[_vm._v("mdi-numeric-3-box-outline")]),_vm._v(" Información del producto ")],1)]),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"source_of_financing","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"disabled":_vm.modelStartingOrder.originId == '',"no-data-text":_vm.$t('global.no_result'),"items":_vm.modelStartingOrder.fiscalFoundList,"label":_vm.$t('label.source_financing'),"required":"","error-messages":errors,"append-icon":"feather icon-chevron-down","clear-icon":"feather icon-x-circle"},on:{"change":_vm.getProductsByFiscalFound},model:{value:(_vm.modelStartingOrder.idFiscalFund),callback:function ($$v) {_vm.$set(_vm.modelStartingOrder, "idFiscalFund", $$v)},expression:"modelStartingOrder.idFiscalFund"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"supplier"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.modelStartingOrder.originId == '' ||
                _vm.modelStartingOrder.loadingSupplier,"no-data-text":_vm.$t('global.no_result'),"items":_vm.modelStartingOrder.supplierList,"label":"Pedido (opcional)","clearable":"","append-icon":"feather icon-chevron-down","clear-icon":"feather icon-x-circle"},on:{"change":_vm.getProductsBySupplier},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.modelStartingOrder.loadingSupplier)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":25,"color":"#037550"}}):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.modelStartingOrder.idSupplier),callback:function ($$v) {_vm.$set(_vm.modelStartingOrder, "idSupplier", $$v)},expression:"modelStartingOrder.idSupplier"}})],1)],1),_c('div',{staticClass:"col-md-12"},[_c('validation-provider',{attrs:{"name":"product","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"disabled":_vm.modelStartingOrder.originId == '' ||
                _vm.modelStartingOrder.loadingProducts,"no-data-text":_vm.$t('global.no_result'),"items":_vm.modelStartingOrder.productsList,"label":_vm.$t('label.product'),"clearable":"","error-messages":errors,"append-icon":"feather icon-chevron-down","clear-icon":"feather icon-x-circle"},on:{"change":_vm.getLotsByProduct},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(data.item.text)+" ")])]}},{key:"append",fn:function(){return [(_vm.modelStartingOrder.loadingProducts)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":25,"color":"#037550"}}):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.modelStartingOrder.idProduct),callback:function ($$v) {_vm.$set(_vm.modelStartingOrder, "idProduct", $$v)},expression:"modelStartingOrder.idProduct"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"lot","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"disabled":_vm.modelStartingOrder.originId == '' ||
                _vm.modelStartingOrder.loadingLot,"no-data-text":_vm.$t('global.no_result'),"items":_vm.modelStartingOrder.lotList,"label":_vm.$t('label.lot'),"required":"","error-messages":errors,"clearable":"","append-icon":"feather icon-chevron-down","clear-icon":"feather icon-x-circle"},on:{"change":_vm.getLots},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.modelStartingOrder.loadingLot)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":25,"color":"#037550"}}):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.modelStartingOrder.idStock),callback:function ($$v) {_vm.$set(_vm.modelStartingOrder, "idStock", $$v)},expression:"modelStartingOrder.idStock"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-2"},[_c('validation-provider',{attrs:{"name":"quantity","rules":"required|numeric|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.modelStartingOrder.originId == '',"error-messages":errors,"label":_vm.$t('label.quantity'),"required":""},model:{value:(_vm.modelStartingOrder.quantity),callback:function ($$v) {_vm.$set(_vm.modelStartingOrder, "quantity", $$v)},expression:"modelStartingOrder.quantity"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6 pt-6"},[_c('button',{staticClass:"btn btn-outline-success ",attrs:{"type":"submit","disabled":invalid}},[_c('i',{staticClass:"feather icon-plus mr-1"}),_vm._v(" "+_vm._s(_vm.$t("global.save_product"))+" ")])])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }