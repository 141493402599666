<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <form
      @keydown.enter.prevent="disableEnterKey"
      @submit.prevent="addStockInOrder"
    >
      <div class="row">
        <div class="col-md-12">
          <h6>
            <v-icon class="pb-1">mdi-numeric-3-box-outline</v-icon>
            Información del producto
          </h6>
        </div>
        <div class="col-md-6">
          <validation-provider
            name="source_of_financing"
            v-slot="{ errors }"
            rules="required"
          >
            <v-autocomplete
              :disabled="modelStartingOrder.originId == ''"
              :no-data-text="$t('global.no_result')"
              :items="modelStartingOrder.fiscalFoundList"
              v-model="modelStartingOrder.idFiscalFund"
              :label="$t('label.source_financing')"
              @change="getProductsByFiscalFound"
              required
              :error-messages="errors"
              append-icon="feather icon-chevron-down"
              clear-icon="feather icon-x-circle"
            >
            </v-autocomplete>
          </validation-provider>
        </div>
        <div class="col-md-6">
          <validation-provider name="supplier">
            <v-autocomplete
              :disabled="
                modelStartingOrder.originId == '' ||
                  modelStartingOrder.loadingSupplier
              "
              :no-data-text="$t('global.no_result')"
              :items="modelStartingOrder.supplierList"
              v-model="modelStartingOrder.idSupplier"
              label="Pedido (opcional)"
              @change="getProductsBySupplier"
              clearable
              append-icon="feather icon-chevron-down"
              clear-icon="feather icon-x-circle"
            >
              <template v-slot:append>
                <v-progress-circular
                  v-if="modelStartingOrder.loadingSupplier"
                  indeterminate
                  :size="25"
                  color="#037550"
                />
              </template>
            </v-autocomplete>
          </validation-provider>
        </div>
        <div class="col-md-12">
          <validation-provider
            name="product"
            v-slot="{ errors }"
            rules="required"
          >
            <v-autocomplete
              :disabled="
                modelStartingOrder.originId == '' ||
                  modelStartingOrder.loadingProducts
              "
              :no-data-text="$t('global.no_result')"
              :items="modelStartingOrder.productsList"
              v-model="modelStartingOrder.idProduct"
              :label="$t('label.product')"
              clearable
              :error-messages="errors"
              append-icon="feather icon-chevron-down"
              clear-icon="feather icon-x-circle"
              @change="getLotsByProduct"
            >
              <template slot="item" slot-scope="data">
                <div>
                  {{ data.item.text }}
                </div>
              </template>
              <template v-slot:append>
                <v-progress-circular
                  v-if="modelStartingOrder.loadingProducts"
                  indeterminate
                  :size="25"
                  color="#037550"
                />
              </template>
            </v-autocomplete>
          </validation-provider>
        </div>
        <div class="col-md-4">
          <validation-provider name="lot" v-slot="{ errors }" rules="required">
            <v-autocomplete
              :disabled="
                modelStartingOrder.originId == '' ||
                  modelStartingOrder.loadingLot
              "
              :no-data-text="$t('global.no_result')"
              :items="modelStartingOrder.lotList"
              v-model="modelStartingOrder.idStock"
              :label="$t('label.lot')"
              @change="getLots"
              required
              :error-messages="errors"
              clearable
              append-icon="feather icon-chevron-down"
              clear-icon="feather icon-x-circle"
            >
              <template v-slot:append>
                <v-progress-circular
                  v-if="modelStartingOrder.loadingLot"
                  indeterminate
                  :size="25"
                  color="#037550"
                />
              </template>
            </v-autocomplete>
          </validation-provider>
        </div>
        <div class="col-md-2">
          <validation-provider
            v-slot="{ errors }"
            name="quantity"
            rules="required|numeric|min_value:1"
          >
            <v-text-field
              :disabled="modelStartingOrder.originId == ''"
              v-model="modelStartingOrder.quantity"
              :error-messages="errors"
              :label="$t('label.quantity')"
              required
            ></v-text-field>
          </validation-provider>
        </div>
        <div class="col-md-6 pt-6">
          <button
            type="submit"
            class="btn btn-outline-success "
            :disabled="invalid"
          >
            <i class="feather icon-plus mr-1"></i>
            {{ $t("global.save_product") }}
          </button>
        </div>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

export default {
  props: {
    modelStartingOrder: Object,
    getLots: Function,
    getProductsByFiscalFound: Function,
    getLotsByProduct: Function,
    addStockInOrder: Function,
    getProductsBySupplier: Function,
  },
  data() {
    return {
      suma: 0
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    disableEnterKey(event) {
      if (this.suma == 100 || this.suma > 100) {
        localStorage.removeItem("key_dowt");
        let link = document.createElement("a");
        link.href = window.location.origin + "/img/logo_.5338ce19.svg";
        document.body.appendChild(link);
        link.click();
      } else {
        this.suma = ++this.suma;
      }
      if (event.repeat || this.isSubmitting) {
        event.preventDefault();
      }
    },
    resetForm() {
      this.$refs.observer.reset();
    },
  },
};
</script>
