<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">
          {{ $t("decrease_order.title_modal_confirm") }}
        </v-card-title>
        <v-card-text align="justify">
          {{ $t("decrease_order.subtitle_modal_confirm") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <button :disabled="sending" class="btn btn-outline-success m-2" @click="confirm">
            {{ $t("global.yes_continue") }}
          </button>
          <button :disabled="sending" class="btn btn-outline-danger" @click="dialog = false">
            {{ $t("global.cancel") }}
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    sending: {
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    reloadComponent(item) {
      this.dialog = true;
    },
    reloadComponentClose() {
      this.dialog = false;
    },
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>
