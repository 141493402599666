<template>
  <div class="p-4">
    <h6 v-show="isDelete">
      <v-icon class="pb-1">mdi-numeric-2-box-outline</v-icon>
      Productos de la salida
    </h6>
    <v-data-table
      :headers="headers"
      :items="dataList"
      :search="search"
      height="auto"
      fixed-header
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      dense
    >
      <template v-slot:top>
        <div class="row justify-end">
          <div
            class="col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 pr-6 text-right"
          >
            <v-text-field
              v-model="search"
              v-on:keyup.enter="searchbar"
              :suffix="search == '' ? '' : 'enter'"
              class="br"
              label="Buscar..."
              ><template v-slot:append>
                <span class="pt-2">
                  <i
                    class="feather icon-corner-down-left"
                    v-show="search != ''"
                  >
                  </i>
                </span> </template
            ></v-text-field>
          </div>
        </div>
      </template>

      <template v-slot:[`item.statusName`]="{ item }">
        <ChipTableComponent
          :status="item.status"
          :statusName="item.statusName"
        />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top v-if="isDelete">
          <template v-slot:activator="{ on, attrs }">
            <span
              :disabled="sending"
              small
              class="mr-2 hand"
              @click="deleteRecord(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-trash-2"></i>
            </span>
          </template>
          <span>{{ $t("table.delete") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="isDetail">
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand mr-2"
              @click="detail(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-eye"></i>
            </span>
          </template>
          <span>{{ $t("table.detail") }}</span>
        </v-tooltip>

        <v-icon
          small
          class="mr-2"
          color="#E2E2E2"
          v-if="isUpdate && (item.status == 6 || item.status == 7)"
        >
          mdi mdi-square-edit-outline
        </v-icon>
        <!--   &&  withPermissionName('Actualizar Merma') -->
        <v-tooltip top v-if="isUpdate && item.status != 6 && item.status != 7">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="updateRecord(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi mdi-square-edit-outline
            </v-icon>
          </template>
          <span>{{ $t("table.edit") }}</span>
        </v-tooltip>

        <v-tooltip top v-if="isDownloadPDF">
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand text-fmedical-red"
              @click="downloadPDF(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="fa fa-file-pdf-o"></i>
            </span>
          </template>
          <span>{{ $t("table.download_PDF") }}</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <ButtonTableComponent v-if="isButton" v-on:excelTable="excelTable" />
  </div>
</template>

<script>
import { UtilFront, PermissionConstants } from "@/core";
import ButtonTableComponent from "@/common/table/ButtonTableComponent";
import ChipTableComponent from "@/common/table/ChipTableComponent";

export default {
  props: {
    headers: {},
    isDelete: false,
    isUpdate: false,
    isDetail: false,
    isDownloadPDF: false,
    isButton: false,
  },
  data() {
    return {
      search: "",
      sending: false,
      dataList: [],
      permissions: PermissionConstants,
    };
  },
  components: {
    ButtonTableComponent,
    ChipTableComponent,
  },
  methods: {
    /* General */
    excelTable() {
      this.$emit("excelTable");
    },
    withPermissionName(namePermission) {
      return UtilFront.withPermissionName(namePermission);
    },
    reloadComponent(dataList) {
      this.dataList = dataList;
    },
    deleteRecord(item) {
      this.$emit("deleteRecord", item);
    },
    updateRecord(item) {
      this.$emit("updateRecord", item);
    },
    downloadPDF(item) {
      this.$emit("downloadPDF", item);
    },
    detail(item) {
      this.$emit("detail", item);
    },
    reloadComponentSending(sending) {
      this.sending = sending;
    },
    itemRowBackground: function(item, index) {
      return item.status == 7 ? "style-2" : "";
    },
  },
  created() {},
};
</script>

<style scoped>
.style-2 {
  background-color: rgb(254, 187, 187);
}
</style>
